#info {
    width: 30%;
    align-items: left;
    align-content: left;
    justify-content: left;
    position: absolute;
    top: 10vh;
    right: 2vw;
    z-index: 100;
}


#info.mobile {
    width: 100%;
    align-items: left;
    align-content: left;
    justify-content: left;
    position: absolute;
    top: 75vh;
    left: 0px;
    z-index: 100;
}

#info.mobile .p-card{
    width: 95%;
    margin: 5px;
}

.mobile .p-card{
    width: 95%;
    margin: 5px;
}


.title {
    font-family: 'Nunito';
    font-size: 40px;
    font-weight: bolder;
    letter-spacing: 5px;
}

.label {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 2px;  
}

.ratings-section {
    margin-bottom: 25px;
}

.ratings-section .label {
    font-family: 'Roboto', sans-serif;
    letter-spacing: 2px;
    margin-bottom: 5px;
}

.ratings-section .score {
    padding: 0px;
    display: inline-block;
    margin-bottom: 10px;
}

.ratings-section .stars {
    display: inline-block;
    margin-right: 15px;
}

.ratings-section .number {
    display: inline-block
}

.tag-section {
    margin-bottom: 25px;
}

.tag-section .label{
    margin-bottom: 5px;
}

.tag-section .tag{
    border-radius: 5px;
    background: #CDDBC4;
    margin: 10px;
    padding: 5px 10px 5px 10px;
    display:inline-block;
    font-weight: bold;
}

.comments-section {
    margin-bottom: 25px;
    text-align: left;
}

.comments-section .label{
    margin-bottom: 25px;
    text-align: center;
}