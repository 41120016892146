
.blog-card-container {
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2%;
}

.blog-card-container .mobile{
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 2%;
}


.blog-post {
    background-color: #F9F9F9;
    border-radius: 20px;
    display: inline-block;
    width: 100%;
    vertical-align: top;
    height: 750px;
    box-shadow: 2px 2px 5px #999;
    overflow-y: hidden;
    /* -webkit-mask-image: linear-gradient(to bottom, black 70%, transparent 110%);
    mask-image: linear-gradient(to bottom, black 70%, transparent 110%); */
    transition: all .2s ease-in-out;

}


.blog-post .mobile {
    background-color: #F9F9F9;
    border-radius: 20px;
    padding: 10px;
    width: 100%;
    display: inline-block;
}

.p-card-subtitle {
    text-align: left;
}


.blog-post img {
    width: 100%;
    height: 300px;
    object-fit: cover;
}

.blog-post:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.05);
}


.blog-post .title {
    margin-top: 0px;
}


.rating-section .overall-rating {
    display: inline-block;
    width: 55%;
    vertical-align: top;
    background-color: #CDDBC4;
    padding: 20px;
    padding-left: auto;
    padding-right: auto;
    border-radius: 5px;


}

.rating-section .overall-rating .rating .label {
    font-family: 'Nunito';
}


.rating-section .overall-rating .metric {
    font-family: 'Nunito';
    font-weight: bolder;
    font-size: 3em;
    text-align: center;
}

.rating-section .overall-rating .rating {
    display: inline-block;
    margin-right: 0px;
    margin-bottom: 0px;
}

.rating-section .overall-rating .rating .label {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 1em;
    font-weight: bold;
}

.rating-section .overall-rating .rating .stars {
    margin-top: 0px;
    font-size: 1.2em;
    font-weight: bold;
}


.rating-section .star-ratings {
    align-content: center;
    /* padding: 20px; */
    display: inline-block;
    width: 45%
}

.rating {
    /* display:inline-block; */
    margin-bottom: 20px;
}

.rating .label {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: bold;
}


.new-post-form {
    width: 60%;
    /* margin-top: 15px; */
    margin-left: auto;
    margin-right: auto;
    padding: 50px;
    border-radius: 10px;
    background-color: #FFF;
    box-shadow: 5px 5px 5px #DDD;
    align-items: left;
    text-align: left;
}

.new-post-form.mobile {
    width: 100%;
    margin: 2px;
    margin-left: 2px;
    margin-right: 2px;
    /* margin-top: 15px; */
    padding: 25px;
    padding-top: 2px;
    border-radius: 10px;
    background-color: #FFF;
    box-shadow: 5px 5px 5px #DDD;
    align-items: left;
    text-align: left;
}


.key-metric {
    background-color: #CDDBC4;
    border-radius: 10px;
    padding: 20px;
    width: 60%;
    /* display: inline-block; */
    margin-top: 55px;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    vertical-align: middle;
    height: 100%;
    text-align: center;
}

.key-metric .label {
    font-family: 'Nunito';
    font-weight: bolder;
    font-size: 40px;
}

.key-metric .score {
    font-family: 'Nunito';
    font-weight: bolder;
    font-size: 100px;
    text-align: center;
}

.label {
    font-weight: bolder;
}

.author-icon {
    display: inline-block;
    font-family: 'Nunito';
    font-weight: bolder;
    margin-top: 10px;
    margin-right: 10px;
    text-align: center;
    vertical-align: middle;
    background-color: #967049;
    color: #FFF;
    font-size: 20px;
    border-radius: 20px;
    padding: 5px;
    padding-top: 7px;
    width: 40px;
    height: 40px;

}

.author-row {
    position: absolute;
    top: 0;
    right: 0;
    /* margin: 0px; */
}

.author-row .author-icon {
    display: inline-block;
    font-family: 'Nunito';
    font-weight: bolder;
    text-align: center;
    vertical-align: middle;
    background-color: #967049;
    color: #FFF;
    font-size: 22px;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    padding-top: 5px;
    margin-top: -10px;
    margin-right: 0px;
    margin-left: 5px;
    /* padding: 0px; */

}


.invalid-section {
    min-height: 200px;
    width: 100%;
    background-color: #EEE;
    border-radius: 10px;
    text-align: center;
    vertical-align: middle;
}

.invalid-section p {
    font-family: 'Nunito';
    /* font-weight: bold; */
    /* margin-top: 95px; */
    padding-top: 90px;
    ;
    margin-bottom: auto;
}

.image-thumbnail {
    width: 100px;
    height: 100px;
    margin: 5px;

}

/* .photo-section {
    background-color: #eee;
    width: 100%;
    margin-bottom: 10px;
    display: block;
    min-height:40vh;
    
}
 */

.blog-post-section {
    width: 66%;
    background-color: #FFF;
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 5%;
    padding-top: 20px;
}

.blog-post-section.mobile {
    width: 100%;
}

.blog-post .short-desc {
    text-align: left;
    font-style: italic;
    min-height: 2.5em;
}


.blog-post-section .title {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 4em;
    font-weight: bold;
    display: block;
}

.blog-post-section .author-section {
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 4em;
    font-weight: bold;
}

.blog-post-section .comments-section {
    background-color: #eee;
    border-radius: 10px;
    padding: 20px;
}

.title-section {
    display: block;
    width: 100%;
}

.detail-section {
    display: block;
    width: 100%;
}

.photo-gallery {
    background-color: #FFF;
    width: 100%;
    height: 40vh;
    white-space: nowrap;
    justify-content: center;
    align-items: top;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.photo-gallery img:hover {
    transition: all .2s ease-in-out;
    transform: scale(1.05);
}


.photo-gallery .primary {
    min-width: 50%;
    min-height: 100%;
    max-width: 50%;
    max-height: 100%;
    display: inline-block;
}

.photo-gallery .primary img {
    min-width: 100%;
    min-height: 100%;
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border: 2px solid white;
    border-radius: 15px;
}

.photo-gallery .secondary {
    min-width: 50%;
    min-height: 100%;
    max-width: 50%;
    max-height: 100%;
    /* display:inline-block; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.photo-gallery .secondary img {
    min-width: 50%;
    min-height: 50%;
    max-width: 50%;
    max-height: 50%;
    object-fit: cover;
    border: 2px solid white;
    border-radius: 15px;
    /* display:inline-block; */
}

.photo-gallery .img {
    object-fit: cover;
    /* border: 5px solid white; */
}


.photo-overlay {
    width: 80vw;
    height: 1000px;
}


.blog-post .avatar {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-left: 5px;
}

.filters-section {
    margin-left: 5%;
    /* margin-bottom: 20px; */
    text-align: left;
    align-content: center;
}

.filters-section .label {
    font-size: 20px;
    color: #967049;
    font-weight: bold;
    display: inline-block;
    vertical-align: middle;
}

.filters-section .filter-selector .p-inputtext{
    margin-right: 2px;
}

.filters-section .filter-selector {
    width: 200px;
    margin-left: 20px;
    font-size: 10px;
    color: #967049;
    vertical-align: middle;
}

.filters-section .direction-selector {
    vertical-align: middle;
}