.footer {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 20px;
    width: 100%;
    background-color: #999;
    color: white;
    text-align: center;
    z-index: 999;
    opacity: 50%;
}

.footer p {
    /* margin: 0px; */
    font-size: 12px;
    /* padding-top: -10px; */
    margin-top: 3px !important;
    /* z-index: 9999999999; */
}

/* .p-menubar {
    background-color: #CDDBC4;
    color: #FFF;
} */

.p-menubar .p-component .active{
    background-color: #8D6945;
}


.content {
    /* background-color: beige; */
    vertical-align: middle;
    height: 100vh;
    width: 95%;
    margin-left:auto;
    margin-right:auto;
    /* background-color:#E9E9E9; */
}

.page-title {
    /* Logo in menubar */
  color: #967049;
  font-size: 50px;
  font-family: 'Nunito';
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 2px;
  padding: 0.5rem 1.5rem;
  border-radius: 6px;
  transition: box-shadow 0.2s;
  user-select: none;
  text-align: left;
  /* Custom menu button style */
  /* background-color: #967049;
  border-radius: 20px; */
  margin-left: 25px;
  margin-right: 20px;
}