.home-page .banner {
    min-height: 20vh;
    background-color: #FFF;
    position : relative
}

.home-page .banner.mobile{
    min-height: 40vh;
    max-height: 20vh;
}

.home-page .banner.mobile img{
    position: relative;
    height: 100px;
    left:0;
}



.home-page .banner .site-title {
    /* Logo in menubar */
    color: #000;
    font-size: 4em;
    font-family: 'Comfortaa';
    font-weight: bold;
    letter-spacing: 2px;
    text-align: left;
    vertical-align: bottom;
    margin-left: 75px;
    margin-right: 50px;
    position : absolute;
    bottom: 0.5em;
}

.home-page .banner .site-title .subtitle{
    /* Logo in menubar */
    color: #555;
    font-size: 0.25em;
    font-family: 'Comfortaa';
    font-weight: bold;
    letter-spacing: 2px;
    display: inline-block;
    margin-left: 20px;
}


.home-page .banner.mobile .site-title {
    /* Logo in menubar */
    position : relative;
    left: -50px;
    bottom: 0px;
    font-size: 50px;
}

.home-avatars {
   align-items: right;
   justify-content: right;
   text-align: right;
    margin-right: 10%;
}

.home-avatars .mobile{
    align-items: middle;
    justify-content: right;
    text-align: right;
    height: 200px;
 }


.home-avatars img {
    /* Logo in menubar */
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 10px;
}

.home-page .page-title {
    margin-left: 0px;
    padding-left: 10px;
    font-size: 40px;
}